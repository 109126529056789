<template>
    <div class="settings-view">
        <CardComponent class="card-component--icon">
            <template #header>
                <RowComponent align="center">
                    <span>General settings</span>
                </RowComponent>
            </template>
            <EditComponent
                v-model="survey.name"
                placeholder="Name your survey here"
                @save="
                    (value) => {
                        handleSave('survey', { name: value })
                    }
                "
            />
            <LoadingComponent
                v-show="loading"
                stroke-color="#579ae6"
                overlay="rgba(255, 255, 255, 0.8)"
            />
            <DividerComponent />
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="multiple-participations"
                        external-link="https://www.surveylegend.com/user-guide/creating-surveys/"
                    />
                    <span>Allow multiple participations</span>
                </RowComponent>
                <template v-if="!kioskEnabled">
                    <ToggleComponent
                        v-model="survey.multipleParticipation"
                        prevent
                        @change="handleChange($event, 'multipleParticipation')"
                    />
                </template>
                <template v-else>
                    <PopoverComponent
                        v-model:visible="visibleMultipleParticipationPopover"
                        width="300px"
                        placement="top-end"
                        :close-delay="0"
                    >
                        <template #trigger>
                            <ToggleComponent
                                :value="true"
                                locked
                                @mouseenter="visibleMultipleParticipationPopover = true"
                                @mouseleave="visibleMultipleParticipationPopover = false"
                            />
                        </template>
                        <p>You <strong>cannot</strong> disable this with kiosk mode enabled.</p>
                    </PopoverComponent>
                </template>
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="collect-ip"
                        external-link="https://www.surveylegend.com/user-guide/track-using-ip-addresses/"
                    />
                    <span>Collect IP-address</span>
                </RowComponent>
                <ToggleComponent
                    v-model="survey.collectIP"
                    prevent
                    @change="handleChange($event, 'collectIP')"
                />
            </RowComponent>
        </CardComponent>
        <PreviewComponent
            :expand="expanded"
            max-height="600px"
            title="Appearance options"
            lead="Customize how your public results appear to your participants."
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="public-results"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/"
                        />
                        <span>Show results to respondents</span>
                    </RowComponent>
                    <template v-if="!kioskEnabled && !survey.hideSubmitButton">
                        <ToggleComponent
                            v-model="surveyLiveAnalytics.displayAfterSurvey"
                            prevent
                            @change="
                                handleChange($event, 'displayAfterSurvey', 'surveyLiveAnalytics')
                            "
                        />
                    </template>
                    <template v-else>
                        <PopoverComponent
                            v-model:visible="visiblePublicResultsPopover"
                            width="300px"
                            placement="top-end"
                            :close-delay="0"
                        >
                            <template #trigger>
                                <ToggleComponent
                                    :value="false"
                                    locked
                                    @mouseenter="visiblePublicResultsPopover = true"
                                    @mouseleave="visiblePublicResultsPopover = false"
                                />
                            </template>
                            <template v-if="kioskEnabled">
                                <p>
                                    You <strong>cannot</strong> enable this with kiosk mode enabled.
                                </p>
                            </template>
                            <template v-else-if="survey.hideSubmitButton">
                                <p>
                                    You <strong>cannot</strong> enable this when submit button is
                                    hidden.
                                </p>
                            </template>
                        </PopoverComponent>
                    </template>
                </RowComponent>
            </template>
            <template #options>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="promote-sharing"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/#promote"
                        />
                        <span>Promote sharing</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyLiveAnalytics.shareResults"
                        prevent
                        short
                        @change="handleChange($event, 'shareResults', 'surveyLiveAnalytics')"
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="promote-participation"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/#promote"
                        />
                        <span>Promote participation</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyLiveAnalytics.shareSurvey"
                        prevent
                        short
                        @change="handleChange($event, 'shareSurvey', 'surveyLiveAnalytics')"
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="percentages"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/#only-display-percentages"
                        />
                        <span>Use percentages</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyLiveAnalytics.onlyDisplayPercentages"
                        prevent
                        short
                        @change="
                            handleChange($event, 'onlyDisplayPercentages', 'surveyLiveAnalytics')
                        "
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="participants-chart"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/#hide-participation-donut"
                        />
                        <span>Hide participants data</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyLiveAnalytics.hideParticipationDonut"
                        prevent
                        short
                        @change="
                            handleChange($event, 'hideParticipationDonut', 'surveyLiveAnalytics')
                        "
                    />
                </RowComponent>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="geographical-reach"
                            external-link="https://www.surveylegend.com/user-guide/show-results-after-survey-is-completed/#hide-world-map"
                        />
                        <span>Hide geographical reach</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyLiveAnalytics.hideWorldMap"
                        prevent
                        short
                        @change="handleChange($event, 'hideWorldMap', 'surveyLiveAnalytics')"
                    />
                </RowComponent>
            </template>
            <template #preview> </template>
        </PreviewComponent>
        <Teleport to="body">
            <UpgradeComponent ref="upgrade-collect-ip" name="collect-ip" image="collect-ip.png" />
            <WarningComponent
                ref="warning-collect-ip"
                name="collect-ip"
                image="collect-ip.png"
                word="collect"
            />
        </Teleport>
    </div>
</template>

<script>
import { db } from '@/firebase'
import { mapGetters } from 'vuex'

const surveys = db.ref('v2/survey_meta/')
const surveysLiveAnalytics = db.ref('v2/share_live_analytics/')

export default {
    name: 'Settings',

    metaInfo: {
        title: 'Settings'
    },

    data() {
        return {
            loading: false,
            survey: {
                name: '',
                kiosk: {}
            },
            surveyLiveAnalytics: {},
            visibleMultipleParticipationPopover: false,
            visiblePublicResultsPopover: false
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        }),

        expanded() {
            return (
                this.surveyLiveAnalytics.displayAfterSurvey &&
                !this.kioskEnabled &&
                !this.survey.hideSubmitButton
            )
        },

        kioskEnabled() {
            return this.survey.kiosk ? this.survey.kiosk.enabled : false
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.loading = true

                this.$rtdbBind('survey', surveys.child(id)).then(() => {
                    this.loading = false
                })

                this.$rtdbBind('surveyLiveAnalytics', surveysLiveAnalytics.child(id)).then(() => {
                    this.loading = false
                })
            }
        }
    },

    methods: {
        handleSave(ref, data) {
            this.$firebaseRefs[ref].update(data)
        },

        async handleChange({ value }, toggle, firebaseRef = 'survey') {
            let permission
            let hasPermission

            if (value) {
                switch (toggle) {
                    case 'collectIP':
                        permission = 'collect-ip'
                        hasPermission = this.permissions[permission]

                        if (hasPermission > 0) {
                            const isConfirmed = await this.$refs[`warning-${permission}`].open()

                            this.$refs[`warning-${permission}`].close()

                            if (!isConfirmed) return
                        }

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (isConfirmed)
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            const data = {}

            data[toggle] = value

            this.handleSave(firebaseRef, data)
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style scoped lang="scss">
.settings-view {
    .loading-component {
        border-radius: 6px;
    }

    .card-component__content > .row-component {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .preview-component__options {
        > .row-component {
            height: 28px;

            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>
